<template>
    <div>
        <header class="block-header">
            Woohaa! No such page...
        </header>
        <img src="../assets/oops.jpg" alt="A nearly broken egg">
        <router-link to="/" class="btn btn-primary">Perhaps try <i class="fa fa-home"></i></router-link>
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
h1 {
    margin: 20px 0;
}

img {
    width: 100%;
    max-width: 640px;
    margin-bottom: 48px;
}

a {
    width: 100%;
}
</style>
